import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Home } from '../home/home';
import { NavMenu } from '../menu/navMenu';
import './layout.css';


export class Layout extends Component {
    static displayName = Layout.name;

  render () {
      return (
          <div>
              <Container >
                  <Home />
              </Container>
              <div className="container content rf-app-content">
                  <NavMenu />
                      {this.props.children}
              </div>
        </div>
    );
  }
}
