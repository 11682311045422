import React, { Component } from 'react';
import './home.css';
import logo from '../../pictures/logo.png';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className="jumbotron jumbotron-fluid rf-jumbotron hidden-print">
                <img alt=""
                    src={logo}
                    className="img-fluid" />
            </div>
        );
    }
}
