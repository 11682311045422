import React, { Component, useState } from "react";
import { Collapse } from "react-bootstrap";
import "./filtre.component.css";

export class FicheFiltre extends Component {
  static displayName = FicheFiltre.name;

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      loading: true,
      shown: {},
      selectedOptionMoyens: "undefined",
      selectedOptionRegions: "undefined",
      checkedItemsSujets: new Map(),
      checkedItemsThemes: new Map(),
      checkedItemsRegions: new Map(),
      checkedItemsMoyens: new Map(),
      titreContenu: "",
      fournisseur: "",
      dureeFormationMax: "",
      dureeFormationMin: "",
      sujets: [],
      themes: [],
      moyens: [],
      regions: [],
      result: [],
    };
    this.search();
  }

  clearAllClick = (event) => {
    this.state.titreContenu = "";
    this.state.fournisseur = "";
    this.state.dureeFormationMax = "";
    this.state.dureeFormationMin = "";
    this.state.checkedItemsSujets = new Map();
    this.state.checkedItemsThemes = new Map();
    this.state.checkedItemsMoyens = new Map();
    this.state.checkedItemsRegions = new Map();
    this.search();
  };

  toggle(index) {
    this.setState({
      shown: {
        ...this.state.shown,
        [index]: !this.state.shown[index],
      },
    });
  }

  handleKeyUp = (event) => {
    this.search();
  };

  async search() {
    var arraySujets = [],
      arrayThemes = [],
      arrayMoyens = [],
      arrayRegions = [];
    this.state.checkedItemsSujets?.forEach((sujet, index) => {
      if (sujet && arraySujets?.indexOf(index) === -1) {
        arraySujets.push(index);
      }
    });
    this.state.checkedItemsThemes?.forEach((theme, index) => {
      if (theme && arrayThemes?.indexOf(index) === -1) {
        arrayThemes.push(index);
      }
    });
    this.state.checkedItemsMoyens?.forEach((moyen, index) => {
      if (moyen && arrayMoyens?.indexOf(index) === -1) {
        arrayMoyens.push(index);
      }
    });
    this.state.checkedItemsRegions?.forEach((region, index) => {
      if (region && arrayRegions?.indexOf(index) === -1) {
        arrayRegions.push(index);
      }
    });
    const data = {
      titreContenu: this.state.titreContenu,
      fournisseur: this.state.fournisseur,
      dureeFormationMin: this.state.dureeFormationMin,
      dureeFormationMax: this.state.dureeFormationMax,
      sujets: arraySujets,
      sousCategories: arrayThemes,
      moyens: { items: arrayMoyens, operator: this.state.selectedOptionMoyens },
      regions: {
        items: arrayRegions,
        operator: this.state.selectedOptionRegions,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    await fetch("/api/fichesformations/filter", requestOptions)
      .then((response) => response.json())
      .then((data) => this.setState({ result: data }));
    this.props.selectedCountFiltered(this.state.result.countFiltered);
    this.props.selectedCount(this.state.result.count);
    this.props.selectedResultsHandler(this.state.result.results);
  }

  componentDidMount() {
    this.populateCategoryData();
  }

  renderTable() {
    return (
      <div className="form-group">
        <div>
          <input
            placeholder="Titre / contenu"
            type="text"
            id="searchTitre"
            name="searchTitre"
            value={this.state.titreContenu}
            onKeyUp={(e) => this.handleKeyUp(e)}
            onChange={(e) => {
              this.setState({ titreContenu: e.target.value });
            }}
            className="form-control"
          />
        </div>
        <div className="mt-2">
          <input
            placeholder="Fournisseur"
            type="text"
            id="searchFournisseur"
            name="searchFournisseur"
            value={this.state.fournisseur}
            onKeyUp={(e) => this.handleKeyUp(e)}
            onChange={(e) => {
              this.setState({ fournisseur: e.target.value });
            }}
            className="form-control"
          />
        </div>

        <div className="mt-2">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={(e) => this.clearAllClick(e)}
          >
            Réinitialiser
          </button>
        </div>

        <hr className="my-2" />

        <div>
          Durée : entre &nbsp;
          <input
            type="number"
            id="searchDureeMin"
            name="searchDureeMin"
            className="form-control"
            value={this.state.dureeFormationMin}
            onKeyUp={(e) => this.handleKeyUp(e)}
            onChange={(e) => {
              this.setState({ dureeFormationMin: e.target.value }, () => {
                this.search();
              });
            }}
          />
          &nbsp; et &nbsp;
          <input
            type="number"
            id="searchDureeMax"
            name="searchDureeMax"
            className="form-control"
            value={this.state.dureeFormationMax}
            onKeyUp={(e) => this.handleKeyUp(e)}
            onChange={(e) => {
              this.setState({ dureeFormationMax: e.target.value }, () => {
                this.search();
              });
            }}
          />
        </div>

        <hr className="my-2" />

        <div className="rf-criteria-group" id="0" key="0">
          <div className="rf-label pointer" onClick={() => this.toggle(0)}>
            Sujet
          </div>
          <div id="sujetsCollapsible" className="rf-collapse">
            <Collapse in={this.state.shown[0]}>
              <div className="rf-padded">
                <domain-value-checkbox>
                  {this.state.sujets?.map((sujet, index) => (
                    <p key={sujet.id}>
                      <input
                        className="custom-control-indicator"
                        id={sujet.id}
                        type="checkbox"
                        value={sujet.value}
                        onChange={(event) => {
                          this.setState(
                            (prevState) => ({
                              checkedItemsSujets:
                                prevState.checkedItemsSujets?.set(
                                  event.target.value,
                                  event.target.checked
                                ),
                            }),
                            () => {
                              this.search();
                            }
                          );
                        }}
                        checked={this.state.checkedItemsSujets?.get(
                          sujet.value
                        )}
                      />
                      <span> {sujet.label} </span>
                    </p>
                  ))}
                </domain-value-checkbox>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="rf-criteria-group" id="1" key="1">
          <div className="rf-label pointer" onClick={() => this.toggle(1)}>
            Thèmes
          </div>
          <div id="sousCategoriesCollapsible" className="rf-collapse">
            <Collapse in={this.state.shown[1]}>
              <div className="rf-padded">
                <domain-value-checkbox ClassName="col-md-6 col-lg-12 col-12">
                  {this.state.themes?.map((theme, index) => (
                    <p key={theme.id}>
                      <input
                        className="custom-control-indicator"
                        id={theme.id}
                        type="checkbox"
                        value={theme.value}
                        onChange={(event) => {
                          this.setState(
                            (prevState) => ({
                              checkedItemsThemes:
                                prevState.checkedItemsThemes?.set(
                                  event.target.value,
                                  event.target.checked
                                ),
                            }),
                            () => {
                              this.search();
                            }
                          );
                        }}
                        checked={this.state.checkedItemsThemes?.get(
                          theme.value
                        )}
                      />
                      <span> {theme.label} </span>
                    </p>
                  ))}
                </domain-value-checkbox>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="rf-criteria-group" id="2" key="2">
          <div className="rf-label pointer" onClick={() => this.toggle(2)}>
            Moyens
          </div>
          <div id="moyensCollapsible" className="rf-collapse">
            <Collapse in={this.state.shown[2]}>
              <div>
                <div className="rf-search-option">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label align-bottom">
                      <input
                        className="form-check-input align-top ng-untouched ng-pristine ng-valid"
                        type="radio"
                        name="inclusifMoyens"
                        value="inclusive"
                        onChange={(event) => {
                          this.setState(
                            { selectedOptionMoyens: event.target.value },
                            () => {
                              this.search();
                            }
                          );
                        }}
                      />
                      Inclusif
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label align-bottom">
                      <input
                        className="form-check-input align-top"
                        defaultChecked="exclusive"
                        type="radio"
                        name="inclusifMoyens"
                        value="exclusive"
                        onChange={(event) => {
                          this.setState(
                            { selectedOptionMoyens: event.target.value },
                            () => {
                              this.search();
                            }
                          );
                        }}
                      />
                      Exclusif
                    </label>
                  </div>
                </div>
                <div className="rf-padded" id="divChoices">
                  <domain-value-checkbox ClassName="col-md-6 col-lg-12 col-12">
                    {this.state.moyens?.map((moyenf, index) => (
                      <p key={moyenf.id}>
                        <input
                          className="custom-control-indicator"
                          id={`checkbox_moyenFormation${moyenf.id}`}
                          value={moyenf.value}
                          type="checkbox"
                          onChange={(event) => {
                            this.setState(
                              (prevState) => ({
                                che: prevState.checkedItemsMoyens?.set(
                                  event.target.value,
                                  event.target.checked
                                ),
                              }),
                              () => {
                                this.search();
                              }
                            );
                          }}
                          checked={this.state.checkedItemsMoyens.get(
                            moyenf.value
                          )}
                        />
                        <span> {moyenf.label} </span>
                      </p>
                    ))}
                  </domain-value-checkbox>
                </div>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="rf-criteria-group" id="3" key="3">
          <div className="rf-label pointer" onClick={() => this.toggle(3)}>
            Régions
          </div>
          <div id="regionsCollapsible" className="rf-collapse">
            <Collapse in={this.state.shown[3]}>
              <div>
                <div className="rf-search-option">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label align-bottom">
                      <input
                        className="form-check-input align-top"
                        type="radio"
                        name="inclusifRegions"
                        value="inclusive"
                        onChange={(event) => {
                          this.setState(
                            { selectedOptionRegions: event.target.value },
                            () => {
                              this.search();
                            }
                          );
                        }}
                      />
                      Inclusif
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label align-bottom">
                      <input
                        className="form-check-input align-top"
                        defaultChecked="exclusive"
                        type="radio"
                        name="inclusifRegions"
                        value="exclusive"
                        onChange={(event) => {
                          this.setState(
                            { selectedOptionRegions: event.target.value },
                            () => {
                              this.search();
                            }
                          );
                        }}
                      />
                      Exclusif
                    </label>
                  </div>
                </div>
                <div className="rf-padded" id="divChoices">
                  <domain-value-checkbox ClassName="col-md-6 col-lg-12 col-12">
                    {this.state.regions?.map((region, index) => (
                      <p key={region.id}>
                        <input
                          className="custom-control-indicator"
                          id={`checkbox_region${region.id}`}
                          value={region.value}
                          type="checkbox"
                          onChange={(event) => {
                            this.setState(
                              (prevState) => ({
                                che: prevState.checkedItemsRegions?.set(
                                  event.target.value,
                                  event.target.checked
                                ),
                              }),
                              () => {
                                this.search();
                              }
                            );
                          }}
                          checked={this.state.checkedItemsRegions?.get(
                            region.value
                          )}
                        />
                        <span> {region.label} </span>
                      </p>
                    ))}
                  </domain-value-checkbox>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.renderTable()
    );
    return <div>{contents}</div>;
  }

  groupArrayOfObjects = function (list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  populateCategoryData() {
    fetch("api/fichesformations/sujetsParTypes")
      .then((response) => response.json())
      .then((responseData) => {
        var groupedtypes = this.groupArrayOfObjects(responseData, "type");
        this.setState({ loading: false, sujets: groupedtypes[1] });
        this.setState({ themes: groupedtypes[3] });
        this.setState({ moyens: groupedtypes[4] });
        this.setState({ regions: groupedtypes[5] });
      })
      .catch((error) => console.warn("erreur: " + error));
  }
}
