import React, { Component } from 'react';
import './contact.css';

export class Contact extends Component {
    static displayName = Contact.name;

    render() {
        return (
            <div className="contenu">
                <h4 class="display-4 mt-4">Pour nous joindre</h4>

                <hr class="my-4" />

                <p>Vous êtes un fournisseur qui offrez une formation aux RSG sur un des sujets suivants :</p>

                <ul>


                    <li>Le rôle de la responsable d’un service de garde en milieu familial ; </li>
                    <li>Le développement de l’enfant ; </li>
                    <li>La sécurité, santé et l’alimentation ;</li>
                    <li>Le programme éducatif. </li>
                </ul>

                <p>Vous aimeriez faire ajouter votre formation dans ce répertoire? Veuillez transmettre un courriel à l’adresse suivante : <a href="mailto:rsg@mfa.gouv.qc.ca">rsg@mfa.gouv.qc.ca</a></p>

                <p>Vous ne recevrez pas de message de confirmation une fois votre courriel envoyé et aucun délai de traitement ne vous sera communiqué.</p>
            </div>
        );
    }
}