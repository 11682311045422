import React, { Component } from 'react';
import './a-propos.css';

export class Apropos extends Component {
    static displayName = Apropos.name;

    render() {
        return (
            <div className="contenu">
                <div className="row">
                    <div className="contenu">
                        <h4 class="display-4 mt-4">À propos du répertoire des formations</h4>

                        <hr class="my-4" />

                        <p>
                            <strong>
                                Bienvenue dans le répertoire d’offre de formations destinées aux personnes responsables d’un service de garde en milieu familial (RSG) du Québec !
                            </strong>
                        </p>

                        <p>
                            Au cours de l’année 2014, toutes les formations incluses dans ce répertoire ont été analysées avec la <em>Grille d’évaluation des formations
                                offertes aux responsables d’un service de garde en milieu familial</em>. En 2016, le contenu du répertoire a été mis à jour et en 2018, une nouvelle version plus conviviale a été élaborée.
                        </p>

                        <p>
                            Cette grille a été développée exclusivement pour évaluer, dans un premier temps, la pertinence des formations et dans un deuxième temps,
                            l’efficacité de ces dernières à soutenir le transfert des apprentissages des formations au milieu de garde afin de contribuer à l’amélioration des pratiques professionnelles des RSG.
                        </p>

                        <p>Ce répertoire poursuit les objectifs suivants: </p>

                        <ul>
                            <li>Répertorier des formations destinées aux RSG qui ont démontré un niveau satisfaisant au regard de leur capacité à soutenir le transfert des apprentissages* chez les apprenantes.</li>
                            <li>Faciliter l’accès des RSG à ces formations en identifiant leur disponibilité dans les régions. </li>
                        </ul>

                        <p>
                            <small>
                                * Note : Dans le cas présent, le transfert des apprentissages réfère à un processus par lequel l’apprenante réutilise, dans son travail, une connaissance ou une
                                compétence acquise lors d’une formation.
                            </small>
                        </p>

                        <p>
                            L’annexe 1 fournit des informations supplémentaires concernant l’élaboration de cette grille par une équipe de recherche spécialisée
                            en éducation à la petite enfance de l’Université du Québec à Montréal (UQAM).
                        </p>

                        <p>
                            Les annexes 2 et 3 présentent le type d’information que les fiches contiennent ainsi que la méthode suggérée pour rechercher facilement une formation dans le répertoire, par
                            région ou par thème associées à chacun des quatre grands sujets de l’article 57 du Règlement sur les services de garde éducatifs à l’enfance, soit :
                        </p>

                        <ul>
                            <li>Le rôle de la responsable d’un service de garde en milieu familial ; </li>
                            <li>Le développement de l’enfant ; </li>
                            <li>La sécurité, santé et l’alimentation ;</li>
                            <li>Le programme éducatif. </li>
                        </ul>

                        <p>
                            Soulignons que la participation à ce projet a eu lieu sur une base volontaire. Ainsi, si une formation ne figure pas au répertoire, cela ne signifie pas que cette
                            dernière ne répond pas aux différents critères d’évaluation établis.
                        </p>

                        <p>
                            Enfin, bien qu’il s’adresse principalement aux RSG, ce répertoire peut également s’avérer fort pertinent pour tous les intervenants du domaine de l’éducation
                            à la petite enfance qui souhaitent se perfectionner pour enrichir leur pratique auprès des enfants d’âge préscolaire.
                        </p>

                        <p>
                            Pour toutes questions de précision concernant les formations offertes au répertoire, veuillez contacter les formateurs directement.
                        </p>

                        <hr class="my-4" />

                        <p>
                            <strong>
                                Annexe 1 : Élaboration de la <em>Grille d’évaluation des formations offertes aux responsables d’un service de garde en milieu familial</em>
                            </strong>
                        </p>

                        <p>
                            Cette grille a été conçue par une équipe de recherche spécialisée en éducation à la petite enfance de l’UQAM dirigée par la professeure
                            titulaire, Nathalie Bigras (PhD). Ce projet a été financé par le comité de la formation continue et du perfectionnement des RSG.
                            Pour déterminer les critères d’évaluation des formations, une recension exhaustive des écrits sur les facteurs associés au transfert des apprentissages
                            a été effectuée pour ensuite procéder à la validation des critères par un comité d’experts et par des analyses de cohérence interne.
                        </p>

                        <p>
                            Ainsi, cette grille a permis de créer un répertoire qui recense des formations dont les activités et les méthodes d’enseignement des formateurs
                            sont orientées vers la transférabilité des apprentissages (ex. enseignement interactif et participatif, présence d’objectifs d’apprentissage et
                            de transfert, activité d’auto-observation et de résolution de problème, etc.). Soulignons toutefois que l'analyse des formations a été réalisée
                            à partir des planifications de formations et non à partir d'une observation directe de ces dernières.
                        </p>

                        <hr class="my-4" />

                        <p>
                            <strong>
                                Annexe 2 : Type d’informations des fiches descriptives des formations
                            </strong>
                        </p>

                        <p>
                            Les informations au sujet de chacune des formations sont consignées sur une fiche descriptive sur laquelle on retrouve tous les renseignements
                            pertinents permettant d’identifier celles répondant aux besoins de la RSG selon l’axe d’intervention : le titre de la formation, le nom du formateur
                            ainsi que ses coordonnées pour le contacter, la durée de la formation, les objectifs, la description des formations (la description et les objectifs
                            de formation ont été rédigés par les formateurs), les moyens de formations (en personne, par correspondance ou en ligne), les régions où sont offertes
                            les formations, le nombre d’heures consacrées à chacun des 4 sujets de l’article 57 du Règlement sur les services de garde éducatifs à l’enfance.
                        </p>

                        <p>
                            Bien qu’une formation puisse inclure des contenus touchant à plus d’un sujet, elle est classée en fonction des contenus prédominants, la plupart du temps en
                            termes d’heures qui y sont consacrées.
                        </p>

                        <div class="card">
                            <div class="card-block">
                                <h5 class="card-title">
                                    Définitions des catégories
                                </h5>

                                <div>
                                    <ul>
                                        <li>1. Sujets de formation</li>
                                        <ul>
                                            <li>1.1 Rôle de la responsable d’un service de garde en milieu familial : formation ou partie de formation consacrée aux fonctions de travail de la RSG ainsi qu’aux caractéristiques organisationnelles d’un service de garde en milieu familial (tâches et opérations, compétences en lien avec la fonction de travail, conditions d’exercice, réglementation, ressources financières et matérielles, etc.).</li>
                                            <li>1.2 Le développement de l’enfant : formation ou partie de formation consacrée à la théorie du développement de l’enfant (fondements, dimensions et stades de développement, difficultés de développement, etc.).</li>
                                            <li>1.3 Sécurité, santé et alimentation : formation ou partie de formation consacrée à des thématiques liées à la sécurité, la santé ou l’alimentation des enfants (aménagements et comportements assurant la sécurité des enfants, hygiène, renouvellement des énergies, gestion du stress, maladies infantiles et médication, saine alimentation, etc.).</li>
                                            <li>1.4 Le programme éducatif : formation ou partie de formation consacrée aux applications de l’action éducative (approches et modèles pédagogiques, stratégies éducatives adaptées aux quatre domaines de l’action éducative : relation avec l’enfant, structuration des activités, aménagement des lieux, relation avec les parents, etc.).</li>
                                        </ul>
                                        <li>2. Moyens de formation : </li>
                                        <ul>
                                            <li>2.1 En personne : formation offerte dans une salle en présence du formateur.</li>
                                            <li>2.2 Par correspondance (format papier) : formation offerte par correspondance en format papier pour la majorité des contenus.</li>
                                            <li>2.3 En ligne : formation offerte sur un support électronique (site internet, vidéoconférence, blogues pour la majorité des contenus). </li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <p>
                            <strong>
                                Annexe 3 : Repérer une formation dans le répertoire
                            </strong>
                        </p>

                        <p>
                            Le répertoire présente des fonctionnalités de recherche par mots-clés notamment en inscrivant ou en sélectionnant :
                        </p>

                        <ul>
                            <li>les titres des formations;</li>
                            <li>le titre ou le contenu des fournisseurs de services;</li>
                            <li>les sujets de formation prévus à l’article 57 du Règlement sur les services de garde éducatifs à l’enfance et thèmes associés;</li>
                            <li>la durée des formations.</li>
                        </ul>

                        <p>
                            D’autres fonctionnalités utiles sont aussi disponibles :
                        </p>

                        <ul>
                            <li>des boutons d’impression dans chaque fiche individuelle et dans la page d’affichage des résultats;</li>
                            <li>une icône sur la barre d’outils pour retourner à vos résultats de recherche;</li>
                            <li>un bouton qui permet de réinitialiser votre recherche.</li>
                        </ul>

                        <p>
                            Les pages de résultats permettent de survoler des informations générales sur les formations :
                        </p>

                        <ul>
                            <li>Nombre de fiches correspondant aux critères de recherche;</li>
                            <li>Le titre et les catégories de sujet de la formation;</li>
                            <li>Le nom du ou des fournisseurs de services qui l’offrent;</li>
                            <li>La durée de la formation;</li>
                            <li>Les moyens de formation disponibles;</li>
                            <li>Les régions où la formation est offerte et celles où les inscriptions sont possibles sur demande.</li>
                        </ul>

                        <p>
                            À l’intérieur de chaque fiche individuelle, les informations complémentaires suivantes sont disponibles :
                        </p>

                        <ul>
                            <li>Les renseignements pour communiquer avec le fournisseur (coordonnées et site web);</li>
                            <li>Les heures de formation associées à chaque sujet règlementaire;</li>
                            <li>La description et les objectifs de la formation;</li>
                            <li>La clientèle cible.</li>
                        </ul>

                        <hr class="my-4" />

                        <p>
                            <strong>
                                Légende des régions administratives :
                            </strong>
                        </p>

                        <div class="pl-4">
                            <strong>01 -</strong>	Bas Saint-Laurent <br />
                            <strong>02 -</strong>	Saguenay-Lac-Saint-Jean <br />
                            <strong>03 -</strong>	Capitale nationale <br />
                            <strong>04 -</strong>	Mauricie <br />
                            <strong>05 -</strong>	Estrie <br />
                            <strong>06 -</strong>	Montréal <br />
                            <strong>07 -</strong>	Outaouais <br />
                            <strong>08 -</strong>	Abitibi- Témiscamingue <br />
                            <strong>09 -</strong>	Côte-Nord <br />
                            <strong>10 -</strong>	Nord-du-Québec <br />
                            <strong>11 -</strong>	Gaspésie-Iles-de-la-Madeleine <br />
                            <strong>12 -</strong>	Chaudière-Appalaches <br />
                            <strong>13 -</strong>	Laval <br />
                            <strong>14 -</strong>	Lanaudière <br />
                            <strong>15 -</strong>	Laurentides <br />
                            <strong>16 -</strong>	Montérégie <br />
                            <strong>17 -</strong>	Centre-du-Québec <br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
