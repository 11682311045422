import React, { Component, useEffect } from "react";
import "./liste.print.component.css";
import { FichesItem } from "./fiche.item";

export class FichesListPrint extends Component {
    static displayName = FichesListPrint.name;
    static print = true;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            print: false,
        };

    }

    renderResult() {

        const fiches = this.props.location.state.fiches;
        const canPrint = false;
        return (
            <div>
                {fiches.map((fiche, findex) => (
                    <div className="rf-page-break">
                        <FichesItem
                            key={`fiche_${fiche.id}`}
                            ficheId={`ficheId=${fiche.id}`}
                            canPrint={canPrint}
                            lastFiche={findex == fiches.length - 1 ? true : false}
                        ></FichesItem>
                    </div>
                ))}
            </div>
        );
    }



    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            this.renderResult()
        );

        return <div>{contents}</div>;
    }
}