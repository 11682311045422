import React, { Component } from "react";
import "./liste.component.css";
import { Link } from "react-router-dom";

export class FichesListe extends Component {
  static displayName = FichesListe.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentPage: 1,
      fichesPerPage: 10,
      startIndex: 0,
      endIndex: 10,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {}

  handleClick(event, id) {
    this.setState(
      {
        currentPage: Number(event == null ? id : event.target.id),
      },
      () => {
        if (event == null)
          this.setState({
            startIndex: 0,
            endIndex: 10,
          });
      }
    );
  }

  renderTable() {
    const { selectedResults } = this.props;
    let { currentPage, fichesPerPage } = this.state;
    const totalNumber = this.props.count;
    const countFiltered = this.props.countFiltered;
    const indexDerniereFiche = currentPage * fichesPerPage;
    const indexPremiereFiche = indexDerniereFiche - fichesPerPage;
    const currentFiches = selectedResults?.slice(
      indexPremiereFiche,
      indexDerniereFiche
    );
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(selectedResults?.length / fichesPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    var found = pageNumbers.find((element) => element === currentPage);
    if (
      (pageNumbers?.length > 0 && typeof found === "undefined") ||
      (this.state.endIndex !== fichesPerPage &&
        this.state.endIndex < pageNumbers?.length)
    ) {
      this.handleClick(null, 1);
    }
    const renderPageNumbers = pageNumbers
      ?.slice(
        this.state.startIndex < 0 ? 0 : this.state.startIndex,
        this.state.endIndex
      )
      .map((number) => {
        return (
          <li
            className={`page-item ${
              number === currentPage ? " page-item active" : null
            }`}
          >
            <a
              className="page-link"
              key={number}
              id={number}
              onClick={(e) => this.handleClick(e)}
            >
              {number}
            </a>
          </li>
        );
      });

    return (
      <div id="search-component" className="rf-fiche-search-result">
        <div id="search-results" className="search-results">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="p-2">
              <h5>Résultats</h5>
              <span className="rf-fiche-search-count-results">
                {countFiltered || countFiltered === 0
                  ? countFiltered
                  : totalNumber}{" "}
                fiches trouvées
              </span>
            </div>
            <div className="p-2">
              {renderPageNumbers.length != 0 && (
                <div>
                  <div className="rf-pagination">
                    <ul className="pagination pagination-sm">
                      <li
                        className={`page-item ${
                          1 === currentPage ? "page-item disabled" : null
                        }`}
                      >
                        <a
                          className="page-link"
                          key="FirstItem"
                          id="FirstItem"
                          onClick={() => {
                            this.setState({ currentPage: 1 }, () => {
                              this.setState({
                                startIndex: 0,
                                endIndex: fichesPerPage,
                              });
                            });
                          }}
                        >
                          Première
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          1 === currentPage ? "page-item disabled" : null
                        }`}
                      >
                        <a
                          className="page-link"
                          key="PreviousItem"
                          id="PreviousItem"
                          onClick={() => {
                            this.setState(
                              { currentPage: Number(currentPage - 1) },
                              () => {
                                if (currentPage <= fichesPerPage) {
                                  this.setState({
                                    startIndex: 0,
                                    endIndex: fichesPerPage,
                                  });
                                }
                              }
                            );
                          }}
                        >
                          «
                        </a>
                      </li>
                      {renderPageNumbers}
                      <li
                        className={`page-item ${
                          pageNumbers?.length === currentPage
                            ? "page-item disabled"
                            : null
                        }`}
                      >
                        <a
                          className="page-link"
                          key="NextItem"
                          id="NextItem"
                          onClick={() => {
                            this.setState(
                              { currentPage: Number(currentPage + 1) },
                              () => {
                                if (currentPage >= fichesPerPage) {
                                  this.setState({
                                    startIndex: fichesPerPage - 1,
                                    endIndex: pageNumbers?.length,
                                  });
                                }
                              }
                            );
                          }}
                        >
                          »
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          pageNumbers?.length === currentPage
                            ? "page-item disabled"
                            : null
                        }`}
                      >
                        <a
                          className="page-link"
                          key="LastItem"
                          id="LastItem"
                          onClick={() => {
                            this.setState(
                              { currentPage: pageNumbers?.length },
                              () => {
                                this.setState({
                                  startIndex:
                                    pageNumbers?.length - fichesPerPage,
                                  endIndex: pageNumbers?.length,
                                });
                              }
                            );
                          }}
                        >
                          Dernière
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="p-2">
              <Link
                to={{
                  pathname: "/fiches/list-print",
                  state: { fiches: selectedResults },
                }}
              >
                <button className="btn btn-primary btn-sm">Imprimer...</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="list-items">
          <div className="list-group rf-liste-resultats">
            {currentFiches?.map((fiche, findex) => (
              <Link
                key={`fiche_${fiche.id}`}
                className="list-group-item list-group-item-action flex-column align-items-start rf-liste-resultats"
                to={`/fiche-item/ficheId=${fiche.id}`}
              >
                <div className="font-weight-bold">{fiche.titre}</div>
                <div className="font-italic">
                  {fiche.sujets?.map((sujet, sindex) => (
                    <span key={`fiche_${fiche.id}_sujet_${sindex}`}>
                      {sujet}
                    </span>
                  ))}
                  /
                  {fiche.sousCategories?.map((sousCategorie, scindex) => (
                    <span key={`fiche_${fiche.id}_sousCategory_${scindex}`}>
                      {sousCategorie}
                    </span>
                  ))}
                </div>
                <div>
                  {fiche.fournisseur !== null && fiche.fournisseur !== "" ? (
                    <span>Fournisseur : {fiche.fournisseur}</span>
                  ) : null}
                </div>
                <div>
                  {fiche.dureeFormation !== null &&
                  fiche.dureeFormation !== "" ? (
                    <span>Durée de la formation : {fiche.dureeFormation}</span>
                  ) : null}
                </div>
                <div>
                  {fiche.moyens.length > 0 ? <span>Moyens : </span> : null}
                  {fiche.moyens.length > 0 ? (
                    <ul className="rf-liste-values">
                      {fiche.moyens?.map((moyen, mindex) => (
                        <li key={`fiche_${fiche.id}_moyen_${mindex}`}>
                          {moyen}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
                <div>
                  {fiche.regionsPersonne.length > 0 ? (
                    <span>
                      Régions où la formation est donnée en personne : 
                    </span>
                  ) : null}
                  {fiche.regionsPersonne.length > 0 ? (
                    <ul className="rf-liste-codes-region">
                      {fiche.regionsPersonne?.map((regionPersonne, rpindex) => (
                        <li key={`fiche_${fiche.id}_regionpersonne_${rpindex}`}>
                            {' '}{regionPersonne}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
                <div>
                  {fiche.regionsDemande.length > 0 ? (
                    <span>
                      Régions où la formation est donnée sur demande : 
                    </span>
                  ) : null}
                  {fiche.regionsDemande.length > 0 ? (
                    <ul className="rf-liste-codes-region">
                      {fiche.regionsDemande?.map((regionDemande, rdindex) => (
                        <li key={`fiche_${fiche.id}_regiondemande_${rdindex}`}>
                            {' '}{regionDemande}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.renderTable()
    );
    return <div>{contents}</div>;
  }
}
