import React, { Component } from "react";
import { FichesListe } from "./fiche.liste";
import { FicheFiltre } from "./fiche.filtre";
import "./fiche.component.css";

export class Fiche extends Component {
  static displayName = Fiche.name;

  constructor(props) {
    super(props);
    this.state = {
      selectedResults: null,
      count: 0,
      countFiltered: null,
    };
  }

  selectedResultsHandler = (selectedResults) => {
    this.setState({
      selectedResults,
    });
  };

  selectedCount = (count) => {
    this.setState({
      count,
    });
  };

  selectedCountFiltered = (countFiltered) => {
    this.setState({
      countFiltered,
    });
  };

  render() {
    const { selectedResults } = this.state;
    return (
      <div id="search-component" className="rf-fiche-search-result">
        <div className="row">
          <div id="search-filters" className="col-12 col-lg-3 rf-search-filter">
            <FicheFiltre
              selectedResultsHandler={this.selectedResultsHandler}
              selectedCount={this.selectedCount}
              selectedCountFiltered={this.selectedCountFiltered}
            />
          </div>
          <div className="col-12 col-lg-9 rf-resultats">
            <FichesListe
              selectedResults={selectedResults}
              count={this.state.count}
              countFiltered={this.state.countFiltered}
            />
          </div>
        </div>
      </div>
    );
  }
}
