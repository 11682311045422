import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/layout/layout";
import { Fiche } from "./modules/formation/components/fiche/fiche";
import { Apropos } from "./components/a-propos/a-propos";
import { Contact } from "./components/contact/contact";
import { FichesItem } from "./modules/formation/components/fiche/fiche.item";
import { FichesListPrint } from "./modules/formation/components/fiche/fiche.liste.print";
import "./style.css";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Route exact path="/" component={Fiche} />
        <Route path="/a-propos" component={Apropos} />
        <Route path="/contact" component={Contact} />
        <Route exact path="/fiche-item/:ficheId" component={FichesItem} />
        <Route exact path="/fiches/list-print" component={FichesListPrint} />
      </Layout>
    );
  }
}
