import React, { Component } from "react";
import "./fiche.item.component.css";

export class FichesItem extends Component {
  static displayName = FichesItem.name;
  static print = false;

  constructor(props) {
    super(props);
    this.state = { title: "", loading: true, print: false, ficheData: {} };

    var ficheId =
      this.props.ficheId === undefined || this.props.ficheId === ""
        ? this.props.match.params["ficheId"]
        : this.props.ficheId;
    fetch("/api/fichesformations/detailfiche/" + ficheId)
      .then((response) => response.json())
      .then((data) => {
          this.setState({ title: "", loading: false, ficheData: data });
          if (this.props.lastFiche == true) {
              window.print()
          }
      });
  }

  getCssTwoColumns = function () {
    if (this.state.print) return "col";
    else return "col-12 col-sm-6";
  };

  renderResult() {
      const fiche = this.state.ficheData;
    return (
      <div className="rf-fiche-container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-wrap justify-content-between hidden-print rf-tool-bar rf-sticky-top-bellow-menu">
              {this.props.canPrint === undefined &&
              this.props.canPrint !== false ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    this.setState({ print: true });
                    window.print();
                    this.setState({ print: false });
                  }}
                >
                  Imprimer...
                </button>
              ) : null}
            </div>
            <div>
              <header>
                <h4 className="display-4">{fiche.titre}</h4>
                <div>
                  <span>Sujet : </span>
                  {fiche.sujets?.map((sujet, sindex) => (
                    <span
                      className="rf-axe-fonction"
                      key={`fiche_${fiche.id}_sujet_${sindex}`}
                    >
                      {sujet}
                    </span>
                  ))}
                </div>
                <div>
                  <span>Sous-catégorie : </span>
                  {fiche.sousCategories?.map((sousCategorie, scindex) => (
                    <span
                      className="rf-axe-fonction"
                      key={`fiche_${fiche.id}_sousCategory_${scindex}`}
                    >
                      {sousCategorie}
                    </span>
                  ))}
                </div>
              </header>
            </div>
            <hr className="my-3" />
            <div className="row mt-2">
              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">Fournisseur</span>
                <span className="rf-value">{fiche.fournisseur}</span>
              </div>

              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">Contact</span>
                <div className="rf-value">{fiche.contact.nom}</div>
                <div className="rf-value"> {fiche.contact.adresse} </div>
                <div className="rf-value"> {fiche.contact.telephone} </div>
                <div className="rf-value"> {fiche.contact.courriel} </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">Site internet</span>
                <span className="rf-value">
                  <a target="_blank" href={fiche.web}>
                    {fiche.web}
                  </a>
                </span>
              </div>

              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">Durée de la formation</span>
                <span className="rf-value">{fiche.dureeFormation} heures</span>
              </div>
            </div>
            <hr className="my-2" />
            <div className="card">
              <div className="card-block">
                <h5 className="card-title">Nombre d'heures consacrées au...</h5>
                <div>
                  <span className="rf-label inline">
                    Rôle de la personne responsable d’un service de garde éducatif en milieu
                    familial
                  </span>
                  <span className="rf-value">
                    {fiche.nbHeures.roleResponsable} heures
                  </span>
                </div>
                <div>
                  <span className="rf-label inline">
                    Développement de l'enfant
                  </span>
                  <span className="rf-value">
                    {fiche.nbHeures.developpementEnfant} heures
                  </span>
                </div>

                <div>
                  <span className="rf-label inline">
                    Sécurité, santé et alimentation
                  </span>
                  <span className="rf-value">
                    {fiche.nbHeures.securiteAlimentation} heures
                  </span>
                </div>
                <div>
                  <span className="rf-label inline">Programme éducatif</span>
                  <span className="rf-value">
                    {fiche.nbHeures.programmeEducatif} heures
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h5 className="mt-3">Description et objectif(s)</h5>
              <div className="card mt-2">
                <div className="card-block">
                  <div
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: fiche.objectifs }}
                  ></div>
                </div>
              </div>
            </div>
            <div></div>
            <div className="row mt-2">
              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">Moyen(s) de formation</span>
                <ul>
                  {fiche.moyens?.map((moyen, mindex) => (
                    <li key={`fiche_${fiche.id}_moyen_${mindex}`}> {moyen}</li>
                  ))}
                </ul>
              </div>
              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">Clientèle(s) cible</span>
                <ul>
                  {fiche.clientsCibles?.map((client, cindex) => (
                      <li key={`fiche_${fiche.id}_client_${cindex}`}>{client}
                          {client === "Autre(s)" && fiche.clientelesCibleAutre !== null ?
                              (
                                  <div>
                                      {"Préciser: " + fiche.clientelesCibleAutre}
                                  </div>
                              ) : ("") 
                          }
                      </li>
                      
                  ))}
                </ul>
              </div>
            </div>
            <hr className="my-3" />

            <div className="row mt-2">
              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">
                  Régions où la formation est donnée en personne
                </span>
                <ul>
                  {fiche.regionsPersonne?.map((regionPersonne, rpindex) => (
                    <li key={`fiche_${fiche.id}_regionpersonne_${rpindex}`}>
                      {regionPersonne}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={this.getCssTwoColumns()}>
                <span className="rf-label">
                  Régions où la formation est donnée sur demande
                </span>
                <ul>
                  {fiche.regionsDemande?.map((regionDemande, rdindex) => (
                    <li key={`fiche_${fiche.id}_regiondemande_${rdindex}`}>
                      {regionDemande}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.renderResult()
    );

    return <div>{contents}</div>;
  }
}
