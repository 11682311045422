import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, Nav, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import homeIcon from '../../pictures/homeIcon.png';
import './navMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <div className="rf-top-nav sticky-top">
                <div className="navbar-brand hidden-lg-up">Menu</div>
                <Navbar expand="md">
                    <NavbarBrand className="navbar-home" href="/" >
                        <img
                            alt="Menu"
                            src={homeIcon}
                            width="30px"
                            height="15px"
                            className="d-inline-block" />
                    </NavbarBrand> 
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse isOpen={this.state.collapsed} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink tag={Link} className="nav-link" to="/a-propos">À propos</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="nav-link" to="/contact">Contact</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div >
        );
    }
}
